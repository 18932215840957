/* const core = {
    darkest: '#000000',
    black: '#373435',
    gray: '#cccccc',
    grayLight: '#7d7775',
    grayLighter: '#353332',
    grayLightest: '#0d0d0d',

    borderMedium: '#3b3b3b',
    border: '#242424',
    borderLight: 'transparent',
    borderLighter: '#131111',
    borderBright: '#0b0a0a',

    primary: '#DCC6B7',
    primaryShade_1: '#e3d1c5',
    primaryShade_2: '#eaddd4',
    primaryShade_3: '#f1e8e2',
    primaryShade_4: '#DCC6B7',
    primary_dark: '#b09e92',

    secondary: '#111111',

    yellow: '#ffe8bc',
    yellow_dark: '#ffce71',
}; */

.PresetDateRangePicker_panel {
  padding: 0 22px 11px 22px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: none;
  border: 2px solid #DCC6B7;
  color: #DCC6B7;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0px
}
.PresetDateRangePicker_button__selected {
  background: #DCC6B7
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #373435
}
.SingleDatePickerInput__withBorder {
  border-color: #242424;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px
}
.SingleDatePickerInput__rtl {
  direction: rtl/* @noflip */
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus {
  background: #242424;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__default:hover {
  background: #242424;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #7d7775;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #7d7775;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #373435;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl/* @noflip */
}
.SingleDatePicker_picker__directionLeft {
  left: 0px/* @noflip */
}
.SingleDatePicker_picker__directionRight {
  right: 0px/* @noflip */
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  /* @noflip */height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #373435
}
.SingleDatePicker_closeButton {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  /* @noflip */padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:hover {
  color: hsla(20, 3%, 10%, 1);
  text-decoration: none
}
.SingleDatePicker_closeButton:focus {
  color: hsla(20, 3%, 10%, 1);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #353332
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: none;
  border: 0px;
  border-radius: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0px;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: none
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0px;
  right: 0px;
  display: none
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #DCC6B7;
  bottom: 0px;
  right: 0px
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #b09e92
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0px;
  right: 0px
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #DCC6B7;
  top: 0px;
  right: 0px
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #b09e92
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0px;
  left: 0px
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #DCC6B7;
  top: 0px;
  left: 0px
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #b09e92
}
.DayPickerKeyboardShortcuts_showSpan {
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #373435;
  border: 1px solid #242424;
  border-radius: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: bold;
  margin: 0px
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0px;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: none
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #353332
}
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #7d7775
}
.DayPickerKeyboardShortcuts_closeSvg:focus {
  fill: #7d7775
}
.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0px
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid transparent;
  color: #cccccc;
  background: #373435
}
.CalendarDay__default:hover {
  background: #DCC6B7;
  border: transparent;
  border-radius: 20px;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #DCC6B7;
  border: 1px double transparent;
  color: inherit
}
.CalendarDay__outside {
  border: 0px;
  background: #373435;
  color: #cccccc
}
.CalendarDay__outside:hover {
  border: 0px
}
.CalendarDay__blocked_minimum_nights {
  background: #DCC6B7;
  border: 1px solid #131111;
  color: #353332;
  border-radius: 20px
}
.CalendarDay__blocked_minimum_nights:hover {
  background: #DCC6B7;
  color: #353332
}
.CalendarDay__blocked_minimum_nights:active {
  background: #DCC6B7;
  color: #353332
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #cccccc
}
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #cccccc
}
.CalendarDay__highlighted_calendar:active {
  background: #ffce71;
  color: #cccccc
}
.CalendarDay__selected_span {
  background: #eaddd4;
  color: #373435;
  border-radius: 20px
}
.CalendarDay__selected_span:hover {
  background: #e3d1c5;
  border: 1px double #e3d1c5;
  color: #373435
}
.CalendarDay__selected_span:active {
  background: #e3d1c5;
  border: 1px double #e3d1c5;
  color: #373435
}
.CalendarDay__selected {
  background: #DCC6B7;
  color: #373435;
  border-radius: 20px
}
.CalendarDay__selected:hover {
  background: #DCC6B7;
  border: 1px double #DCC6B7;
  color: #373435
}
.CalendarDay__selected:active {
  background: #DCC6B7;
  border: 1px double #DCC6B7;
  color: #373435
}
.CalendarDay__hovered_span {
  background: #DCC6B7;
  border-radius: 20px;
  color: #111111
}
.CalendarDay__hovered_span:hover {
  background: #DCC6B7;
  border: 1px double #f1e8e2;
  color: #111111
}
.CalendarDay__hovered_span:active {
  background: #f1e8e2;
  border: 1px double #f1e8e2;
  color: #111111
}
.CalendarDay__blocked_calendar {
  background: #353332;
  border: 1px solid #353332;
  color: #7d7775
}
.CalendarDay__blocked_calendar:hover {
  background: #353332;
  border: 1px solid #353332;
  color: #7d7775
}
.CalendarDay__blocked_calendar:active {
  background: #353332;
  border: 1px solid #353332;
  color: #7d7775
}
.CalendarDay__blocked_out_of_range {
  background: #373435;
  border: 1px solid transparent;
  color: #666666
}
.CalendarDay__blocked_out_of_range:hover {
  background: #373435;
  border: 1px solid transparent;
  color: #666666
}
.CalendarDay__blocked_out_of_range:active {
  background: #373435;
  border: 1px solid transparent;
  color: #353332
}
.CalendarDay__hovered_start_first_possible_end {
  background: #131111;
  border: 1px double #131111
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #131111;
  border: 1px double transparent
}
.CalendarMonth {
  background: #373435;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0px
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #cccccc;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial;
  text-transform: capitalize
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #373435;
  text-align: left;
  /* @noflip */z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px/* @noflip */
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0px
}
.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0px;
  left: 0px/* @noflip */
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation__bottom {
  height: auto
}
.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0px;
  margin: 0px
}
.DayPickerNavigation_button__default {
  border: 1px solid transparent;
  background-color: #373435;
  color: #8a8a8a
}
.DayPickerNavigation_button__default:focus {
  border: 1px solid #3b3b3b
}
.DayPickerNavigation_button__default:hover {
  border: 1px solid #3b3b3b;
  background-color: #DCC6B7
  
}
.DayPickerNavigation_button__default:active {
  background: #373435
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 30px;
  margin-top: -10px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px/* @noflip */
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px/* @noflip */
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #373435;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  /* @noflip */position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0px/* @noflip */
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #cccccc;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #cccccc
}
.DayPickerNavigation_svg__disabled {
  fill: #373435
}
.DayPicker {
  background: #373435;
  position: relative;
  text-align: left/* @noflip */
}
.DayPicker__horizontal {
  background: #373435
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05),0 0 0 1px rgba(0, 0, 0, 0.07);
  /* @noflip */border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  /* @noflip */top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: none
}
.DayPicker_calendarInfo__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px/* @noflip */
}
.DayPicker_weekHeader {
  color: #8a8a8a;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left/* @noflip */
}
.DayPicker_weekHeader__vertical {
  left: 50%/* @noflip */
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0px;
  display: table-row;
  border-bottom: 1px solid #242424;
  background: #373435;
  margin-left: 0px;
  /* @noflip */left: 0px;
  /* @noflip */width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0px;
  /* @noflip */padding-right: 0px;
  /* @noflip */font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  /* @noflip */left: 0px;
  /* @noflip */overflow-y: scroll
}
.DateInput {
  margin: 0px;
  padding: 0px;
  background: transparent;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 112px
}
.DateInput__block {
  width: 100%
}
.DateInput_input {
  text-transform: capitalize;
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: white;
  background-color: transparent;
  width: 100%;
  padding: 0px 0px;
  padding-top: 11px;
  padding-bottom: 9px;
  padding-left: 11px;
  /* @noflip */padding-right: 11px;
  /* @noflip */border: 0px;
  border-top: 0px;
  border-right: 0px;
  /* @noflip */border-bottom: 2px solid transparent;
  border-left: 0px;
  /* @noflip */border-radius: 0px
}
.DateInput_input__small {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 0px 0px;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 7px;
  /* @noflip */padding-right: 7px/* @noflip */
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0px;
  background: #373435;
  border: 0px;
  border-top: 0px;
  border-right: 0px;
  /* @noflip */border-bottom: 2px solid #b09e92;
  border-left: 0px/* @noflip */
}
.DateInput_input__disabled {
  font-style: normal;
}
.DateInput_screenReaderMessage {
  border: 0px;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #373435
}
.DateInput_fangStroke {
  stroke: #242424;
  fill: transparent
}
.DateRangePickerInput {
  background-color: transparent;
  display: inline-block
}
.DateRangePickerInput__withBorder {
  border-color: #242424;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px
}
.DateRangePickerInput__rtl {
  direction: rtl/* @noflip */
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #cccccc
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #cccccc;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus {
  background: #242424;
  border-radius: 50%
}
.DateRangePickerInput_clearDates_default:hover {
  background: #242424;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #7d7775;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #7d7775;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #373435;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl/* @noflip */
}
.DateRangePicker_picker__directionLeft {
  left: 0px/* @noflip */
}
.DateRangePicker_picker__directionRight {
  right: 0px/* @noflip */
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  /* @noflip */height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #373435
}
.DateRangePicker_closeButton {
  background: none;
  border: 0px;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  /* @noflip */padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:hover {
  color: hsla(20, 3%, 10%, 1);
  text-decoration: none
}
.DateRangePicker_closeButton:focus {
  color: hsla(20, 3%, 10%, 1);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #353332
}